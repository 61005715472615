<template>
  <sidenav
    v-if="showSidenav"
    :custom-class="color"
    :class="['isTransparent', isRTL ? 'fixed-end' : 'fixed-start']"
  />
  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
  >
    <!-- nav -->
    <navbar
      v-if="showNavbar"
      :text-white="isAbsolute ? 'text-white opacity-8' : ''"
      :min-nav="navbarMinimize"
    />
    <router-view :key="$route.fullPath" />
    <app-footer v-show="showFooter" />

  </main>
</template>
<script>
import Sidenav from "./examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/views/easy-social/Footer.vue";
import { mapMutations, mapState } from "vuex";
import api from "@/api/api";
export default {
  name: "App",
  components: {
    Sidenav,
    Configurator,
    Navbar,
    AppFooter,
  },
  computed: {
    ...mapState([
      "isTransparent",
      "isRTL",
      "isNavFixed",
      "isAbsolute",
      "navbarFixed",
      "absolute",
      "color",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
    ]),
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
  async mounted() {
    if(localStorage.getItem('AUTH_TOKEN')) {
      await api.post('/auth/validateToken?token='+localStorage.getItem('AUTH_TOKEN')).then((x) => {
        if(x.data.status !== 200) {
          new Error('Anmeldung abgelaufen')
        }
      }).catch((e) => {
        this.$router.push({name: 'easy social login'})
      })
    } else {
      if (location.href.includes("password-reset/")) {
        await this.$router.push({name: 'Password Reset', params: {token: location.href.split("password-reset/")[1]}})
      }
      else {
        await this.$router.push({name: 'easy social login'})
      }
    }
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
  },
};
</script>
