<template>
  <div>
    <cropper
        ref="cropper"
        class="cropper"
        :stencil-props="{
          aspectRatio: imgAspectRation === '9/16'? 9/16 : 1/1,
        }"
        :src="propImg"
        @change="change"
    />
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import Navigation from "./VueCropperNavigation.vue";
import {ref} from "vue";

export default {
  name: "VueCropper",
  components: {
    Cropper,
    Navigation,
  },
  data: () => ({
    coordinates: ref(''),
    canvas: ref(''),
    cropImg: "",
  }),
  props: {
    imageCropListData: {
      type: Array
    },
    index: {
      type: Number
    },
    propImg: {
      type: String,
      default: ""
    },
    identifier: {
      type: Number,
      default: "",
      require: true
    },
    imgAspectRation: {
      type: String,
      default: '9/16',
      validator(value, props) {
        // The value must match one of these strings
        return ['9/16', '1/1',].includes(value)
      }
    }
  },
};
</script>

