<template xmlns="http://www.w3.org/1999/html">
  <div class="py-4 container-fluid">
    <div class="row px-2">
      <div class="col-lg-12">
        <a @click="goBackToGallery" class="py-3 mb-5">
          <button
              type="button"
              class="btn bg-light"
          >
            <i class="fa fa-arrow-left pe-2"></i>
            Beiträge
          </button>
        </a>
        <div
            class="col-lg-4 d-flex d-md-none align-items-center justify-content-end gap-3 pb-5"
        >
          <button
              type="button"
              class="mt-2 mb-0 btn bg-dark "
              data-bs-toggle="modal"
              data-bs-target="#emojiPickerModal"
              :disabled="statusOpen"
          >
            Löschen
          </button>
          <button
              @click="savePost"
              type="button"
              class="mt-2 mb-0 btn bg-primary"
          >
            Speichern
          </button>
          <div v-if="showAlert" class="justify-content-end d-flex m-0" style="color:#1ab023">Gespeichert!</div>
        </div>

        <div class="d-flex row">
          <div class="col-md-3 col-sm">
            <p>
              Beschreibe mit Text und Hashtags den Inhalt deines Bildes
            </p>
          </div>
          <div class="col-md-2 col-sm"><label>Vollständigkeit</label>
            <p :class="`mb-2 text-sm fw-bold text-${statusConverterColor(post)}`">{{ statusConverterName(post) }}</p>
          </div>
          <div class="col-md-3 col-sm">
            <label class="form-label">Status</label>
            <select
                id="image-status"
                class="form-control"
                name="change Status"
                v-model="post.status">
              >
            </select>
          </div>
          <div class="col-md-4 col-lg col-md d-none d-md-flex align-items-center justify-content-end gap-3">
            <button
                @click=""
                type="button"
                class="mt-2 mb-0 btn bg-dark"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
                :disabled="statusOpen"
            >
              Löschen
            </button>
            <button
                @click="savePost"
                type="button"
                class="mt-2 mb-0 btn bg-primary"
                :disabled="statusOpen"
            >
              Speichern
            </button>
          </div>
          <div v-if="showAlert" class="justify-content-end d-flex m-0" style="color:#1ab023">Gespeichert!</div>
        </div>

      </div>
      <div v-if="post?.declinedReason" class="card mt-3 pt-3 bg-warning">
        <p>
          <b>Ablehnungsgrund: </b>
          {{ post.declinedReason }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-auto col-md-auto card pt-3 pb-3 my-3" >
        <div class="pb-4 d-flex justify-content-center align-items-center">
          <AddImage :id="parseInt(post.id)"></AddImage>
        </div>

        <div  v-for="(img, idx) in post.images" :key="idx" v-if="post.images.length > 0">
          <img v-if="checkForImage(img) && slide === idx"
               class="border-radius-lg"
               style="max-height: 50rem; object-fit: contain; max-width: 30rem;min-width:20em;min-height:20em;"
               :src="img?.base64Image"
               alt="product_image"
          />
          <div v-if="!checkForImage(img) && slide === idx">
            <video style="min-height:20em; max-height: 30rem; object-fit: contain; max-width: 30rem; min-width:30rem" :src="img?.base64Image" muted autoplay
                   controls class="col-12 mb-3"></video>
          </div>
          <div style="display: flex; justify-content: space-between;">
            <div class="btn-arrow-image">
              <soft-button v-if="slide === idx" @click="slide--"
                           style="margin-top: 10px;" :style="slide > 0 ? '' : 'display: contents'"
              >
                <i class="fa fa-arrow-left"></i>
              </soft-button>
            </div>


            <div class="d-flex gap-2 ">
              <label for="checkbox" v-if="slide === idx" class="m-0 d-flex align-items-center fs-6 cursor-pointer">
                Bild entfernen:
              </label>
              <input class="cursor-pointer" type="checkbox" name="checkbox" id="checkbox" :value="img" v-model="deleteImages"
                     v-if="slide === idx"
                />
            </div>

            <div class="btn-arrow-image">
              <soft-button v-if="slide < post.images.length-1 && slide === idx" @click="slide++"
                           style="margin-top: 10px;">
                <i class="fa fa-arrow-right"></i>
              </soft-button>
            </div>
          </div>
        </div>
      </div>

      <div class="col my-3">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Bild Informationen</h5>
            <div class="row">
              <div class="col-12 mb-1">
                <div class="mb-3">
                  <label for="textFormInput" class="form-label">Text</label>
                  <textarea v-model="post.text" class="form-control" id="textFormInput" rows="5" :disabled="statusOpen"
                            maxlength="5000"></textarea>
                </div>
              </div>
              <div class="col-12 mb-3">
                <div class="mb-3">
                  <label for="hashtagFormInput" class="form-label">Hashtags</label>
                  <textarea v-model="post.hashtags" class="form-control" id="hashtagFormInput" rows="2" :disabled="statusOpen"
                            maxlength="3000"></textarea>
                </div>
                <div class="row">
                  <div class="col-6 mb-3">
                    <label>Zeitpunkt</label>
                    <soft-input
                        id="zeitpunkt"
                        v-model="post.time"
                        dataformatas="24hr"
                        filled
                        type="time"
                        :statusOpen="statusOpen"
                    />
                  </div>
                  <div class="col-6 mb-3">
                    <label>Datum</label>
                    <soft-input
                        id="datum"
                        :statusOpen="statusOpen"
                        v-model="post.date"
                        filled
                        type="date"
                    />
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <div class="mb-3">
                    <label for="additionalInfoTextFormInput" class="form-label">Zusatzinformationen (z.B.
                      Zeitplan)</label>
                    <textarea v-model="post.additionalInfoText" class="form-control" id="additionalInfoTextFormInput" :disabled="statusOpen"
                              rows="2" maxlength="3000"></textarea>
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">Kategorie</label>
                  <select
                      id="category-Select"
                      class="form-control m-auto"
                      name="categorySelect"
                      :disabled="!statusOpen"
                      v-model="post.category">
                    >
                  </select>
                </div>
                <div class="col-12 mb-4">
                  <label class="form-label">Verwendungszweck</label>
                  <select
                      id="post-usage"
                      class="form-control"
                      name="usage"
                      :disabled="statusOpen"
                      v-model="post.usage">
                    >
                  </select>
                </div>
                <div class="form-label mb-2">Social-Media-Plattform (min. 1)</div>
                <div class="gap-2 d-flex flex-wrap" id="social-platform">
                  <button type="button" style="border:1px" :style="instagramStyle" class="badge badge-lg" :disabled="statusOpen"
                          @click="this.post.instagram = !this.post.instagram">
                    <font-awesome-icon :icon="['fab', 'instagram']" size="lg" />
                  </button>
                  <button type="button" style="border:1px" :style="facebookStyle" class="badge badge-lg" :disabled="statusOpen"
                          @click="this.post.facebook = !this.post.facebook">
                    <font-awesome-icon :icon="['fab', 'facebook']" size="lg" />
                  </button>
                  <button type="button" style="border:1px" :style="linkedinStyle" class="badge badge-lg" :disabled="statusOpen"
                          @click="this.post.linkedin = !this.post.linkedin">
                    <font-awesome-icon :icon="['fab', 'linkedin']" size="lg" />
                  </button>
                  <p v-if="socialMediaErrorMessage" class="text-danger">Bitte wählen Sie mindestens
                    Social-Media-Platform</p>
                </div>
              </div>
              <div class="col-12 mb-3">
                <div class="mb-3" v-if="post.instagram  && post.instagramText !== '' && post.instagramText !== 'true'">
                  <div class="d-flex justify-content-between">
                    <label for="instaText" class="form-label">Instagram</label>
                    <div>
                      <button
                          class="border-0"
                          @click="showPickerFun('instagram')"
                          style="background-color:white"
                      >
                        <font-awesome-icon :icon="['far', 'face-laugh']" />
                      </button>

                      <button @click="copy('instaText')" class="border-0" style="background-color:white" :disabled="statusOpen"><i
                        class="fa fa-clipboard"></i></button>
                    </div>
                  </div>
                  <textarea v-model="post.instagramText" class="form-control" id="instaText" rows="5" :disabled="statusOpen"
                            maxlength="3000"></textarea>
                </div>
                <div class="mb-3" v-if="post.facebook  && post.facebookText !== '' && post.facebookText !== 'true'">
                  <div class="d-flex justify-content-between">
                    <label for="facebookText" class="form-label">Facebook</label>
                    <div>
                      <button
                          class="border-0"
                          @click="showPickerFun('facebook')"
                          style="background-color:white"
                      >
                        <font-awesome-icon :icon="['far', 'face-laugh']" />
                      </button>

                      <button @click="copy('facebookText')" class="border-0" style="background-color:white" :disabled="statusOpen">
                        <i class="fa fa-clipboard"></i>
                      </button>
                    </div>
                  </div>
                  <textarea v-model="post.facebookText" class="form-control" id="facebookText" rows="5" :disabled="statusOpen"
                            maxlength="3000"></textarea>
                </div>
                <div class="mb-3" v-if="post.linkedin && post.linkedinText !== '' && post.linkedinText !== 'true'">
                  <div class="d-flex justify-content-between">
                    <label for="linkedinText" class="form-label">LinkedIn</label>
                    <div>
                      <button
                          class="border-0"
                          @click="showPickerFun('linkedin')"
                          style="background-color:white"
                      >
                        <font-awesome-icon :icon="['far', 'face-laugh']" />
                      </button>

                      <button @click="copy('linkedinText')" class="border-0" style="background-color:white" :disabled="statusOpen"><i
                          class="fa fa-clipboard"></i>
                      </button>
                    </div>
                  </div>
                  <textarea v-model="post.linkedinText" class="form-control" id="linkedinText" rows="5" :disabled="statusOpen"
                            maxlength="3000"></textarea>
                </div>
                <div class="mb-3" v-else-if="noSocialMediaTexts">
                  <p class="form-label">Text mit Chat KI für alle Platformen generieren lassen</p>
                </div>

                <div class="modal fade" id="emojiPickerModal" role="dialog" aria-labelledby="emojiPickerModal">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content-emoji modal-content">
                      <div class="modal-body">
                        <Picker :data="emojiIndex" set="twitter" @select="showEmoji" />
                        <button
                            class="mb-0 btn bg-dark js-btn-next text-white"
                            type="button"
                            title="Zurücksetzen"
                            data-bs-dismiss="modal"
                            ref="close"
                        >
                          Schließen
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                    v-if="!chatGPTApiStateLoading"
                    @click="generatechatGPTText"
                    type="button"
                    class="mt-2 mb-0 btn bg-info-soft"
                    id="kiFormInput"
                    :disabled="statusOpen"
                >
                  Generieren
                </button>
                <button
                    v-if="chatGPTApiStateLoading"
                    :disabled="chatGPTApiStateLoading"
                    type="button"
                    class="mt-2 mb-0 btn bg-info-soft"
                >
                  Generiert...
                </button>
                <p v-if="chatError">Es ist ein fehler beim Texteladen aufgetreten</p>

                <div class="modal fade" id="deleteModal" tabindex="-1"
                     aria-labelledby="deleteModal"
                     aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-body">
                        <div>
                          Sind Sie sich sicher, dass Sie diesen Post löschen wollen?
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn" id="closebtn"
                                style="color:white; background-color: #333"
                                data-bs-dismiss="modal">Abbrechen
                        </button>
                        <button type="button" class="btn btn-primary" @click="deletePost">Löschen</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            class="col-lg-4 d-flex d-md-none align-items-center justify-content-end gap-3 mt-3"
        >
          <button
              @click="hideSideNav"
              type="button"
              class="mt-2 mb-0 btn bg-dark"
              data-bs-toggle="modal"
              data-bs-target="#deleteModal"
              :disabled="statusOpen"
          >
            Löschen
          </button>
          <button
              @click="savePost"
              type="button"
              class="mt-2 mb-0 btn bg-primary"
              :disabled="statusOpen"
          >
            Speichern
          </button>
          <div v-if="showAlert" class="justify-content-end d-flex m-0" style="color:#1ab023">Gespeichert!</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import api, {apiGet, apiDelete, apiPost} from "@/api/api";
import SoftInput from "@/components/SoftInput.vue";
import moment from "moment";
import SoftButton from "@/components/SoftButton.vue";

import data from "emoji-mart-vue-fast/data/all.json";
import "emoji-mart-vue-fast/css/emoji-mart.css";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast/src";
import {Modal} from "bootstrap";
let emojiIndex = new EmojiIndex(data);

import AddImage from "@/views/easy-social/AddImage.vue";

export default {
  name: "SinglePostView",
  components: {SoftButton, SoftInput, Picker, AddImage},
  data() {
    return {
      selectedText: '',
      emojiIndex: emojiIndex,
      deleteImages: [],
      socialMediaErrorMessage: false,
      post: {
        text: '',
        hashtags: '',
        instagramText: '',
        facebookText: '',
        linkedinText: '',
        time: '',
        date: '',
        images: '',
        choicesInstants: null,
        additionalInfoText: '',
        status: 'OPEN',
        declinedReason: null,
        usage: 'Beitrag',
        instagram: false,
        facebook: false,
        linkedin: false,
        category: '',
      },
      slide: 0,
      chatGPTApiStateLoading: false,
      showAlert: false,
      chatError: false,
    }
  },
  computed: {
    instagramStyle() {
      return this.post.instagram ? 'color: white; background-color: #e91e63' : 'color: grey'
    },
    facebookStyle() {
      return this.post.facebook ? 'color: white; background-color: #1a237e' : 'color: grey'
    },
    linkedinStyle() {
      return this.post.linkedin ? 'color: white; background-color: #2196f3' : 'color: grey'
    },
    validateSocialMedia() {
      return this.post.instagram || this.post.facebook || this.post.linkedin
    },
    noSocialMediaTexts() {
      return this.post.instagramText === '' || this.post.instagramText === 'true' && this.post.facebookText === '' || this.post.facebookText === 'true' && this.post.linkedinText === '' || this.post.linkedinText === 'true'
    },
    statusOpen(){
      return this.post.status === 'OPEN'
    }

  },
  methods: {
    showPickerFun(selection){
      this.selectedText = selection
      this.uniqueModal = new Modal(document.getElementById("emojiPickerModal"),{ keyboard: false });
      this.uniqueModal.show();
    },
    showEmoji(emoji) {
      if(this.selectedText === 'facebook'){
        this.post.facebookText += emoji.native
      }else if(this.selectedText === 'linkedin'){
        this.post.linkedinText += emoji.native
      }else if(this.selectedText === 'instagram'){
        this.post.instagramText += emoji.native
      }
      this.$refs.close.click()
    },
    statusConverterName(item) {
      const nbr = item.text !== '' ? 0 : item.text !== '' ? 1 : item.text === '' ? 1 : 2
      return nbr === 0 ? 'Vollständig' : nbr === 1 ? 'Unvollständig' : nbr === 2 ? 'Fertig' : 'Fehler'
    },
    statusConverterColor(item) {
      const nbr = item.text !== '' ? 0 : item.text !== '' ? 1 : item.text === '' ? 1 : 2
      return nbr === 0 ? 'success' : nbr === 1 ? 'warning' : 'dark'
    },
    copy(id) {
      var copyText = document.getElementById(id);
      copyText.select();
      navigator.clipboard.writeText(copyText.value);
    },
    async savePost() {
      this.socialMediaErrorMessage = false
      if (this.validateSocialMedia) {
        if (new Date(this.post.date + ' ' + this.post.time) !== null) {
          if (this.deleteImages.length > 0)
          {
            let imagesIdArray = this.deleteImages.map(i => i.id)
            await apiPost(localStorage.getItem('adminId'), "/post/disableImages?postId=" + this.post.id, imagesIdArray)
            this.post.images = this.post.images.filter(i => imagesIdArray.indexOf(i.id) < 0)
            this.deleteImages = []
          }

          this.slide = 0

          let tempCategory
          if (this.post.category.category) {
            tempCategory = this.post.category.category
          } else {
            tempCategory = this.post.category
          }

          await api.put('post/save?companyId=' + this.$store.state.activeId.toString(), {
            text: encodeURI(this.post.text.trim()),
            hashtags: encodeURI(this.post.hashtags.trim()),
            status: this.post.status,
            usage: this.post.usage,
            category: tempCategory,
            images: this.post.images,
            publicationDate: new Date(this.post.date + ' ' + this.post.time),
            additionalInfo: encodeURI(this.post.additionalInfoText.trim()),
            instagramText: this.post.instagram && this.post.instagramText === 'true' || this.post.instagram && this.post.instagramText === '' ? 'true' : this.post.instagram && this.post.instagramText !== '' && this.post.instagram && this.post.instagramText !== 'true' ? encodeURI(this.post.instagramText.trim()) : '',
            facebookText: this.post.facebook && this.post.facebookText === 'true' || this.post.facebook && this.post.facebookText === '' ? 'true' : this.post.facebook && this.post.facebookText !== '' && this.post.facebook && this.post.facebookText !== 'true' ? encodeURI(this.post.facebookText.trim()) : '',
            linkedinText: this.post.linkedin && this.post.linkedinText === 'true' || this.post.linkedin && this.post.linkedinText === '' ? 'true' : this.post.linkedin && this.post.linkedinText !== '' && this.post.linkedin && this.post.linkedinText !== 'true' ? encodeURI(this.post.linkedinText.trim()) : '',
            id: this.post.id
          }).then(() => {
            this.showAlert = true;
            setTimeout(() => {
              this.showAlert = false;
            }, 3000)
          })
        }
      } else {
        const scrollToElementY = document.getElementById('social-platform').getBoundingClientRect().top
        window.scrollTo(0, scrollToElementY);
        this.socialMediaErrorMessage = true;
      }
    },
    async generatechatGPTText() {
      this.chatGPTApiStateLoading = true
      document.body.style.cursor = 'progress';

      await api.post('chat/create', {
        text: encodeURI(this.post.text.trim()),
        hashtags: encodeURI(this.post.hashtags.trim()),
        socialPlatform: '',
      }).then((resp) => {
        if (this.post.instagram && this.post.instagramText === 'true' || this.post.instagram && this.post.instagramText === '') {
          this.post.instagramText = resp.data.Instagram
        }
        if (this.post.facebook && this.post.facebookText === 'true' || this.post.facebook && this.post.facebookText === '') {
          this.post.facebookText = resp.data.Facebook
        }
        if (this.post.linkedin && this.post.linkedinText === 'true' || this.post.linkedin && this.post.linkedinText === '') {
          this.post.linkedinText = resp.data.LinkedIn
        }
      }).finally(() => {
        this.savePost(false);
      }).catch((e) => {
        console.log(e)
        this.chatGPTApiStateLoading = false
        document.body.style.cursor = 'default';
        this.chatError = true
        setTimeout(() => {
          this.chatError = false;
        }, 3000)
      })

      this.chatGPTApiStateLoading = false
      document.body.style.cursor = 'default';
    },
    hideSideNav() {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      sidenav_show.classList.add("g-sidenav-hidden");
      sidenav_show.classList.remove("g-sidenav-pinned");
    },
    goBackToGallery() {
      this.$router.push({path: '/posts'})
    },
    async deletePost() {
      await api.post('post/delete', [this.$route.query.postId]).then(() => {
        this.$router.push({name: 'Posts', query: {c: this.$route.query.c}})
      })
    },
    checkForImage(item){
        return item['base64Image'].includes('image')
    },
  },
  async mounted() {
    this.$store.state.showNavbarMini = false;

    const resp = await apiGet(localStorage.getItem('companyId'), 'post/edit?id=' + this.$route.query.postId)
    this.post = resp.data
    this.post.text = decodeURI(resp.data.text)
    this.post.hashtags = decodeURI(resp.data.hashtags)
    this.post.date = decodeURI(moment(resp.data.publicationDate).format('yyyy-MM-DD'))
    this.post.time = decodeURI(moment(resp.data.publicationDate).format('HH:mm'))
    let response = await apiGet(localStorage.getItem('adminId'), 'post/images?postId=' + this.$route.query.postId)
    this.post.images = response.data

    this.post.status = resp.data.status
    this.post.declinedReason = resp.data.declinedReason
    this.post.category = resp.data.category
    this.post.usage = decodeURI(resp.data.usage)
    this.post.additionalInfoText = decodeURI(resp.data.additionalInfo)
    this.post.id = this.$route.query.postId
    this.post.instagram = resp.data.instagramText !== ''
    this.post.facebook = resp.data.facebookText !== ''
    this.post.linkedin = resp.data.linkedinText !== ''
    this.post.instagramText = decodeURI(resp.data.instagramText)
    this.post.facebookText = decodeURI(resp.data.facebookText)
    this.post.linkedinText = decodeURI(resp.data.linkedinText)

    if (this.post.additionalInfoText === 'null') this.post.additionalInfoText = ''

    if (document.getElementById("image-status")) {
      const status = document.getElementById("image-status");
      this.choicesInstants = new Choices(status, {
        itemSelectText: '',
        searchEnabled: false,
      })

      this.choicesInstants.setChoices([
            {id: 'OPEN', name: 'Offen', selected: this.post.status === 'OPEN'},
            {id: 'IN_POGRESS', name: 'In Bearbeitung', selected: this.post.status === 'IN_POGRESS'},
            {id: 'IN_EXAMINATION', name: 'Zur Kundenfreigabe', selected: this.post.status === 'IN_EXAMINATION'}],
          'name', 'name', false)
      const postId = this.post.id
      this.choicesInstants.passedElement.element.addEventListener(
          'change',
          async function (event) {
            await api.put('post/status', {
              postId: postId,
              status: event.target.value
            })
          },
          false,
      );
    }

    //Category select field

    if (document.getElementById("category-Select")) {

      const status = document.getElementById("category-Select");
      this.choicesInstants = new Choices(status, {
        itemSelectText: '',
        searchEnabled: false,
      })

      const resp = await apiGet(localStorage.getItem('companyId'), '/company/categories?companyId=' + this.$store.state.activeId)
      const categoryOptions = []
      let category = null

      for (let i = 0; i < resp.data.length; i++) {
        category = {
          id: resp.data[i].id,
          name: resp.data[i].category,
          selected: this.post.category?.id === resp.data[i].id
        }
        categoryOptions.push(category);
      }

      this.choicesInstants.setChoices(categoryOptions,
          'name', 'name', false)
    }

    if (document.getElementById("post-usage")) {
      const usage = document.getElementById("post-usage");
      this.choicesInstants = new Choices(usage, {
        searchEnabled: false,
      })

      this.choicesInstants.setChoices([
            {id: 'Entry', name: 'Beitrag', selected: this.post.usage === 'Beitrag'},
            {id: 'Campagne', name: 'Kampagne', selected: this.post.usage === 'Kampagne'}],
          'name', 'name', false)
    }
  },
};
</script>
<style>
.btn-arrow-image{
  display:flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}

.choices[data-type*=select-one] > .choices__list > .choices__list > .choices__item--selectable {
  padding-right: 0;
}

.choices[data-type*=select-one] > .choices__list > .choices__list > .choices__item--selectable::after {
  display: none;
}

.modal-content-emoji{
  width: min-content !important; /* Adjust to fit the content */
}
*::after {
  content: none !important;
}




</style>