/**
=========================================================
* Vue Soft UI Dashboard PRO - v3.0.0
=========================================================
* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import SoftUIDashboard from "./soft-ui-dashboard";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faUserSecret, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faFaceLaugh } from '@fortawesome/free-regular-svg-icons'; // Import the regular icon


/* add icons to the library */
library.add(faUserSecret, faCheck, faXmark, faInstagram, faLinkedin, faFacebook, faFaceLaugh)

const appInstance = createApp(App).component('font-awesome-icon', FontAwesomeIcon);
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(SoftUIDashboard);
appInstance.mount("#app");
