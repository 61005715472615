<template>
  <div class="container-fluid">
    <div class="mx-4 mt-5 overflow-hidden card card-body shadow-blur text-primary bg-white ">
      <div class="row  gx-4 flex-wrap justify-content-start">
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-0">Kategorien</h5>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="py-4 container-fluid">
        <div class="mb-4 card ">
          <div class="d-flex p-3 align-items-center">
            <div class="pb-0 w-100 d-flex justify-content-between">
              <soft-input
                  v-model="searchInput"
                  placeholder="Suchen"
                  icon="fa fa-search"
                  iconDir="right"
                  class="m-0"/>
              <button id="add" type="button" class="btn lsm-grey mb-0 text-white" data-bs-toggle="modal"
                      data-bs-target="#exampleModal" @click="hideSideNav">
                Neue Kategorie
              </button>

              <div class="modal fade pe-4" id="exampleModal" tabindex="-1"
                   aria-labelledby="exampleModalLabel"
                   aria-hidden="true">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header d-flex row" style="margin-left: 5px; margin-right:5px">
                      <h1 class="modal-title fs-5 text-wrap" id="exampleModalLabel">Neue Kategorie
                        erstellen</h1>
                    </div>
                    <div class="modal-body">
                      <label>Kategorie</label>
                      <soft-input placeholder="" v-model="newCategory" id="newCategory"></soft-input>
                      <div id="existsMessage" class="exists-message">
                        Es gibt bereits eine Kategorie die so heißt.
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn" id="closebtn"
                              style="color:white; background-color: #333"
                              data-bs-dismiss="modal" @click="clear()">Abbrechen
                      </button>
                      <button type="button" class="btn btn-primary" @click="createCategory">Erstellen</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex p-3 flex-column">
            <div v-for="(item, idx) in searchList" :key="idx">
              <div class="d-flex justify-content-between">
                {{ item.category }}
                <div class="d-flex align-items-center">
                  <div :id="'category-exists' + item.id" class="tooltip2 exists-message">
                    <button type="button" class="border-0 bg-white" data-bs-toggle="modal"
                            data-bs-target="#deleteModal">
                      <i class="fa fa-question me-3 mb-3"></i>
                    </button>
                    <div class="modal fade pe-4" id="deleteModal" tabindex="-1"
                         aria-labelledby="exampleModalLabel"
                         aria-hidden="true">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header d-flex row" style="margin-left: 5px; margin-right:5px">
                            <h1 class="modal-title fs-5 text-wrap" id="deleteModaLabel">Die Kategorie wird benutzt</h1>
                          </div>
                          <div class="modal-body">
                            <div>Es gibt Beiträge mit dieser Kategorie, Sie müssen das ändern, bevor Sie diese Kategorie
                              löschen können
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn" id="closebtn"
                                    style="color:white; background-color: #333"
                                    data-bs-dismiss="modal" @click="clear()">Zurück
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button :id="'btn' + item" type="button" class="btn btn-primary"
                          @click="deleteCategory(item.id, item.category)">
                    Löschen
                  </button>
                </div>
              </div>
            </div>
            <div class="p-3" v-if="searchInput !== '' && searchList.length === 0">
              Zu der Suchanfrage gab es kein Ergebnis.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
import api, {apiGet, apiPost} from "@/api/api";
import setNavPills from "@/assets/js/nav-pills";
import setTooltip from "@/assets/js/tooltip";
import SoftInput from "@/components/SoftInput.vue";
import PostCard from "@/components/PostCard.vue";

export default {
  components: {PostCard, SoftInput},
  data: function () {
    return {
      categories: [],
      loadingData: false,
      categoryNameList: [],
      searchInput: "",
      categoriesList: [],
      newCategory: "",
    }
  },

  methods: {
    async deleteCategory(id, category) {
      let exists = false;
      let resp = await apiGet(localStorage.getItem('adminId'), '/post?companyId=' + this.$store.state.activeId)

      resp.data.posts.forEach((p) => {
        if (p?.category?.id === id) {
          exists = true
        }
      })
      if (!exists) {
        let companyId
        if (JSON.parse(localStorage.getItem('companyId'))) {
          companyId = JSON.parse(localStorage.getItem('companyId'));
        }
        if (JSON.parse(sessionStorage.getItem('companyId'))) {
          companyId = JSON.parse(sessionStorage.getItem('companyId'));
        }
        await apiPost(localStorage.getItem('adminId'), '/company/deleteCategory?categoryId=' + id + '&companyId=' + this.$store.state.activeId).then(() => {
          this.categories = this.categories.filter(c => c.id !== id);
          this.categoryNameList = this.categoryNameList.filter(c => !c.match(category));
        })
      } else {
        let existsMessage = document.getElementById("category-exists" + id)
        existsMessage.classList.remove('exists-message')
      }
    },
    async setCompany() {
      let resp;
      this.loadingData = true;
      resp = await apiGet(this.$store.state.activeId, '/company/categories?companyId=' + this.$store.state.activeId)
      this.categories = resp.data
      this.categoryNameList = this.categories.map((x) => x.category)
      this.loadingData = false;
    },
    async createCategory() {
      if (!this.categoryNameList.map((a) => {
        return a.toLowerCase()
      }).includes(this.newCategory.toLowerCase())) {
        try {
          let resp;
          let companyId
          if (JSON.parse(localStorage.getItem('companyId'))) {
            companyId = JSON.parse(localStorage.getItem('companyId'));
          }
          if (JSON.parse(sessionStorage.getItem('companyId'))) {
            companyId = JSON.parse(sessionStorage.getItem('companyId'));
          }
          resp = await apiPost(companyId, '/company/newCategory?companyId=' + this.$store.state.activeId, {
            category: this.newCategory
          })
          this.categories.push({
            category: this.newCategory,
            id: resp.data.id
          })
          document.getElementById('closebtn').click();
        } catch (e) {
          //Fehlermeldung
        }
      } else {
        this.existsMessage()
      }
      this.newCategory = ''
    },
    clear() {
      this.newCategory = ''
      this.showSideNav()
    },
    existsMessage() {
      const existsText = document.getElementById("existsMessage")
      existsText.classList.remove('exists-message')
      setTimeout(() => {
        existsText.classList.add('exists-message')
      }, 5000)
    },
    hideSideNav() {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      sidenav_show.classList.add("g-sidenav-hidden");
      sidenav_show.classList.remove("g-sidenav-pinned");
      sidenav_show.children[0].style.setProperty("background-color", "grey", "");

      document.getElementById("loginBtn").style.setProperty("background-color", 'grey', 'important');
      document.getElementById("icon-div").style.setProperty("background-color", 'grey', 'important');
      document.querySelector(".router-link-exact-active").style.setProperty("background-color", 'grey', 'important');
    },
    showSideNav(){
      const sidenav_show = document.querySelector(".g-sidenav-show");
      sidenav_show.classList.remove("g-sidenav-hidden");
      sidenav_show.classList.add("g-sidenav-pinned");
      sidenav_show.children[0].style.setProperty("background-color", "white", "important");

      document.getElementById("loginBtn").style.setProperty("background-color", '#7e1145', 'important');
      document.getElementById("icon-div").style.setProperty("background-color", 'white', 'important');
      document.querySelector(".router-link-exact-active").style.setProperty("background-color", 'white', 'important');
    }
  },

  mounted() {
    this.showSideNav();
    this.$store.state.showNavbarMini = false;
    this.$store.state.isAbsolute = true;

    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    this.setCompany()
    this.categoryNameList = this.categories.map((x) => x.category)

    let modalOpen = false
    if (document.body.classList.contains('modal-open')) {
      modalOpen = true
    }

    // Erstelle einen neuen MutationObserver
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.attributeName === 'class') {
          if (!document.body.classList.contains('modal-open')) {
            this.showSideNav()
          }
        }
      }
    });
    observer.observe(document.body, { attributes: true });
  },

  computed: {
    searchList() {
      return this.searchInput === '' ? this.categories : this.categories.filter((category) => (category.category.toLowerCase().match(this.searchInput.toLowerCase())))
    },
  },
};

</script>
<style>
.exists-message {
  display: none !important;
}

.tooltip2 {
  position: relative;
  display: inline-block;
}

.tooltip2 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip2:hover .tooltiptext {
  visibility: visible;
}
</style>