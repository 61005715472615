<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div :class="validTokenResponse === 'valid' && !done ? '' : 'hide'" class="mt-6 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-primary" id="welcomeText">
                    Password Reset
                  </h3>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start" @submit.prevent="changePassword">
                    <label id="newPasswordLabel">Neues Passwort</label>
                    <div class="password-container">
                      <soft-input
                          id="password0"
                          v-model="newPassword"
                          type="password"
                          placeholder="Neues Passwort"
                          name="new password"
                          class="password-input"
                      />
                      <a class="toggle-password">
                        <span class="password-icon eye-open"><i class="fa fa-eye"></i></span>
                        <span class="password-icon hide eye-closed"><i class="fa fa-eye-slash"></i></span>
                      </a>
                    </div>
                    <label id="newPasswordLabel2">Neues Passwort wiederholen</label>
                    <div class="password-container">
                      <soft-input
                          id="password1"
                          v-model="newPassword2"
                          type="password"
                          placeholder="Neues Passwort wiederholen"
                          name="new password2"
                          class="password-input"
                      />
                      <a class="toggle-password">
                        <span class="password-icon eye-open"><i class="fa fa-eye"></i></span>
                        <span class="password-icon hide eye-closed"><i class="fa fa-eye-slash"></i></span>
                      </a>
                    </div>

                    <p v-if="wrongPassword" class="text-danger" id="alertText">Das Passwörter stimmen nicht überein</p>
                    <div class="text-center">
                      <soft-button
                          class="my-4 mb-2"
                          color="dark"
                          full-width
                          type="submit"
                          id="loginBtn"
                      >Ändern
                      </soft-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    <router-link
                        to="/contact"
                        id="youNeedHelpBtn"
                        class="text-dark font-weight-bold">
                      Brauchst Du hilfe?
                    </router-link
                    >
                  </p>
                </div>
              </div>
              <div :class="validTokenResponse === 'invalidToken' ? '' : 'hide'" class="mt-6 card card-plain">
                <div class="pb-0 text-start">
                  <h3 class="font-weight-bolder text-primary" id="welcomeText">
                    Invalider Token
                  </h3>
                </div>
                <div>
                  Der Token existiert nicht, bitte stellen Sie sicher, dass der Token in der URL mit der, der E-Mail übereinstimmt
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto my-4 text-sm">
                    <router-link
                        to="/contact"
                        id="youNeedHelpBtn"
                        class="text-dark font-weight-bold">
                      Brauchst Du hilfe?
                    </router-link
                    >
                  </p>
                </div>
              </div>
              <div :class="validTokenResponse === 'expired' ? '' : 'hide'" class="mt-6 card card-plain">
                <div class="pb-0 text-start">
                  <h3 class="font-weight-bolder text-primary" id="welcomeText">
                    Der Token ist ausgelaufen
                  </h3>
                </div>
                <div>
                  Der Token hat die zeitliche Begrenzung überschritten, bitte fordern Sie eine neue E-Mail an.
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto my-4 text-sm">
                    <router-link
                        to="/contact"
                        id="youNeedHelpBtn"
                        class="text-dark font-weight-bold">
                      Brauchst Du hilfe?
                    </router-link
                    >
                  </p>
                </div>
              </div>
              <div :class="done ? '' : 'hide'" class="mt-6 card card-plain">
                <div class="pb-0 text-start">
                  <h3 class="font-weight-bolder text-primary" id="welcomeText">
                    Ihr Password wurde geändert
                  </h3>
                </div>
                <div>
                  Gehen Sie zurück in die App um sich Einlogen zu können
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto my-4 text-sm">
                    <router-link
                        to="/contact"
                        id="youNeedHelpBtn"
                        class="text-dark font-weight-bold">
                      Brauchst Du hilfe?
                    </router-link
                    >
                  </p>
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div
                  class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                    class="bg-cover border-primary oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6 login-background-image"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer/>
</template>

<script>
import AppFooter from "./Footer.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftButton from "@/components/SoftButton.vue";
import {mapMutations} from "vuex";
import api, {apiGet} from "@/api/api";
import SoftSnackbar from "@/components/SoftSnackbar.vue";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "EasySocialLogin",
  data() {
    return {
      newPassword: '',
      newPassword2: '',
      wrongPassword: false,
      userId: 1,
      token: "",
      validTokenResponse: "valid",
      done: false,
    }
  },
  components: {
    SoftSnackbar,
    AppFooter,
    SoftInput,
    SoftSwitch,
    SoftButton,
  },

  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");

  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");

  },
  mounted() {
    const togglePasswordBtn = document.querySelectorAll(".toggle-password")
    const eyeClosed = document.querySelectorAll(".eye-closed")
    const eyeOpen = document.querySelectorAll(".eye-open")

    for (let i = 0; i < togglePasswordBtn.length; i++) {
      togglePasswordBtn[i].addEventListener("click", function () {

        const password = document.querySelectorAll("#password" + i)

        if (password[0].type === "text") {
          eyeClosed[i].classList.add("hide")
          eyeOpen[i].classList.remove("hide")
          password[0].type = "password"
        } else {
          eyeClosed[i].classList.remove("hide")
          eyeOpen[i].classList.add("hide")
          password[0].type = "text"
        }

      })
    }
    this.token = location.href.split("password-reset/")[1]
    this.validateToken()
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    async validateToken(){
      let resp = await api.post('/auth/validateResetToken?token=' + this.token)
      this.validTokenResponse = resp.data
    },

    async changePassword() {
      this.wrongPassword = this.newPassword !== this.newPassword2

      if (!this.wrongPassword)
      {
        await api.post('/auth/changePassword?token=' + this.token, {
          username: '',
          password: this.newPassword
        })
            .then( (r) => {
              this.done = true
            })
      }
    },
  },
};

</script>
<style>
.password-container {
  position: relative;
}

.password-icon {
  position: absolute;
  top: 7px;
  right: 15px;
  cursor: pointer;
}

.hide {
  display: none !important;
}
</style>
